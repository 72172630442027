.footer {
    background-color: #141414;
    padding-top: 45px;
    padding-bottom: 125px;
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    font-family: 'Istok Web', sans-serif;
}

.footer h2 {
    font-size: 28px;
    margin-top: 0;
    color: #fff;
    font-weight: normal;
}
.footer span p {
    margin: 0.5rem 0;
    font-size: 16px;
}

.cotainer {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    width: 100%;
    max-width: 1200px;
    padding: 0 40px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: baseline;
}

.cotainer p {
    margin-top: 20px;
}

.cotainer>p>span>a {
    font-size: 24px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

@media (min-width: 992px) {
    .footer {    
        padding-top: 45px;
        padding-bottom: 125px;
    }
    .footer p, .footer h2, .footer span {
        text-align: left;
    }
}

@media (max-width: 768px) {
    .footer {
        padding-top: 45px;
        padding-bottom: 90px;
        font-size: 14px; 
    }
    .footer p, .footer h2, .footer span {
        text-align: left;
    }
    .cotainer>p>span>a {
        font-size: 18px;
    }
} 
@media (max-width: 576px) {
    .footer {
        padding-top: 45px;
        padding-bottom: 90px;
        font-size: 14px;
    }
    .footer p, .footer h2, .footer span {
        text-align: left;
    }
    .cotainer>p>span>a {
        font-size: 18px;
    }
}
  