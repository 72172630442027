.content {
    text-align: center;
    padding: 2rem;
  }

.content h1 {
    font-size: 50px;
    margin-bottom: 1rem;
}
  
.content p {
    font-size: 19px;
    margin-top: 10px;
}  

.social-button {
    background-color: #306965; /* Зелений фоновий колір */
    width: 50px; /* Встановіть ширину кнопки */
    height: 50px; /* Встановіть висоту кнопки, щоб зробити її квадратною */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-right: 10px; /* Відступ справа для відокремлення кнопок */
    border-radius: 1px; /* Заокруглення кутів */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25); /* Додайте тінь для ефекту підняття */
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .social-button:last-child {
    margin-right: 0; /* Видаліть відступ для останньої кнопки */
  }
  
  .social-button svg {
    color: white; /* Колір іконки */
    width: 24px; /* Розмір іконки */
    height: auto;
  }
  
  .social-button:hover {
    background-color: #408c87; /* Темніший відтінок зеленого для ефекту наведення */
    cursor: pointer;
  }
  
  /* Ваш контейнер для соціальних кнопок може виглядати так */
  .social-buttons-container {
    display: flex;
    justify-content: center; /* Центрування кнопок */
    padding: 20px;
  }

 .w-btns-group {
    justify-content: center;
    display: flex;
    margin-top: 40px;
 } 

  .btn-primary.btn-course {
    padding: 12px 25px;
    margin: calc(var(--w-btns-group-item-offset) / 2);
    background-color: #306965; /* Базовий фоновий колір */
    border-color: transparent;
    border-style: solid;
    border-radius: 2px;
    font-size: 15px;
    line-height: calc(var(--ui-button-line-height) * 1em);
    font-family: 'Asap', sans-serif;
}

.btn-primary.btn-course:hover {
    background-color: #408c87; /* Фоновий колір при наведенні */
    transition: color .2s ease-in;
    border-color: transparent;
    border-style: solid;
}

@media (min-width: 992px) {
    .content {    
        padding-top: 120px;
        padding-bottom: 285px;
    }

    .content h1 {
        font-size: 50px;
        margin-bottom: 1rem;
    }
}

@media (max-width: 768px) {
    .content {
        padding-top: 50px;
        padding-bottom: 90px;
        font-size: 14px; 
    }
    .content h1 {
        font-size: 25px;
        margin-bottom: 1.5rem;
    }
} 
@media (max-width: 576px) {
    .content {
        padding-top: 50px;
        padding-bottom: 90px;
        font-size: 14px;
    }
    .content h1 {
        font-size: 25px;
        margin-bottom: 1.5rem;
    }
}
  
  