.app {
  margin: 0;
  padding: 0;
  font-family: 'Asap', sans-serif; 
  color: #fff;
}
h1, h2 {
  font-family: 'Asap', sans-serif;
}
span, p {
  font-family: 'Istok Web', sans-serif;
}