.header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; 
    padding: 1rem;
}
.App-logo {
    max-width: 200px;
    width: 10%; 
    height: auto;
}

@media (max-width: 768px) {
    .App-logo {
        width: 40%; 
        height: auto;
    }
} 
@media (max-width: 576px) {
    .App-logo {
        width: 40%; 
        height: auto;
    }
}
  